<template>
	<div id="main-wrapper" class="inner-pages depbonus-page refer-page zulutools-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>ZULUTRADE</h2>
				<p>Do not trade alone- Join the largest social trading network</p>
			</div>
		</div>
		<div class="section bonus-trade">
			<div class="wrappe2r">
				<div class="img-holder wow fadeInLeft col t-center">
					<img src="assets/images/zulut-img1.webp" alt="AAAFx" title="AAAFx" width="630" height="353" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInRight col">
					<div class="title-holder">
						<p>At AAAFx, you get a chance to trade institutional grade liquidity and make use of our superior trading conditions. However, trading can sometimes be highly taxing on your time as well as on your emotions, which can be hindrance to a successful trading experience for some people.
						<br><br>
						We have got your back! Along with an extraordinary trading experience at AAAFx, you can also get access to an extraordinary world of social trading.
						<br><br>
						Incorporated in 2007, ZuluTrade helps investors in discovering exceptional traders from around the globe and copying their strategies to trade for themselves. This is a social and copy trading online platform which allows users to adopt expert plan of action for their trades. It ranks the experts' performance and allows the trader to readily notice and copy trades from their portfolio. Today, it is one of the most popular social trading networks.
						</p>
					</div>
				</div>

			</div>
		</div>
		<div class="section avail-bonus">
			<div class="wrapper2">
				<div class="section benefits-sec">
					<div class="benefits-holder col wow fadeInLeft">
						<img src="assets/images/zulu-ico.webp" alt="AAAFx" title="AAAFx" width="69" height="75" class="fluid-img" />
						<h3>Become a Copier</h3>
						<p>With our ZuluTrade Account, you get direct and automatic and direct access to a plethora of opportunities for making profits through Zulu connection.
						<br><br>
						You can simply review the trading performance of Expert traders, copy them, and just relax!</p>
						<div class="btn-holder t-right">
							<a :href="siteurl2" target="_blank" class="btn btn-blue">REGISTER</a>
						</div>
					</div>
					<div class="benefits-holder bene-refer col wow fadeInRight">
							<img src="assets/images/zulut-ico1.webp" alt="AAAFx" title="AAAFx" width="72" height="72" class="fluid-img" />
							<h3>Become a Master</h3>
							<p>If you want to become a Master trader and want others to see and copy your trading expertise, you get that option too with us. Your AAAFx account is automatically connected to Zulu.
							<br><br>
							So, you get to earn profits in your trading account and earn performance fees from your copiers too!</p>
							<div class="btn-holder t-right">
								<a :href="siteurl2" target="_blank" class="btn btn-white">REGISTER</a>
							</div>
					</div>
				</div>
				<div class="section-small ib-program">
					<div class="title-holder wow fadeIn">
						<h5>ZuluTrade offers a variety of features and products, some of them are listed below:</h5>
					</div>
					<div class="listing wow fadeIn">
						<div class="zulu-offer">
							<h3>ZuluTrade Social Platform:</h3>
							<p>Whether you are a trader and have a successful trading strategy to share, or you are an investor and want to discuss Zulu Traders and their performance, here is a one stop forum. ZuluTrade has an extensive social trading community platform, that includes blogs, Trading Discussions and Social Feed. This means a chance to interact with the trading community and learn from them as well.</p>
							<br>
						</div>
						<div class="zulu-offer">
							<h3>ZuluTrade Guard:</h3>
							<p>ZuluTrade Guard offers account protection by monitoring the expert’s behavior and unfollows the signal provider in case any alterations are made in the trading strategy.
							<br><br>
							It does so by calculating an exit value for the open trades in the account and when the threshold is hit, trades are closed</p>
						</div>
						<div class="zulu-offer">
							<h3>Automator:</h3>
							<p>Automator stands for Automated Trading. It refers to trading through pre-defined rules for entry and exit of the trades performed by an automated program. It helps in elimination of trading emotions and saves time.</p>
						</div>
						<div class="zulu-offer">
							<h3>Combos:</h3>
							<p>Traders are grouped by ZuluTrade in combinations based on their risk appetites and strategies. The investor can choose a combo to follow. Each combo is managed by Zulu Trade’s Certified Management Portfolio Team.</p>
						</div>
					</div>
				</div>
				<div class="section-small zulu-vid">
					<div class="wrapper">
						<div class="video-holder">
							<iframe width="100%" height="500px" src="https://www.youtube.com/embed/yz4qeidVkvw" frameborder="0"></iframe>
							<!-- <img src="assets/images/video-holder2.webp" alt=""> -->
						</div>
					</div>
				</div>
				<div class="section-small quick-steps mt4-page">
					<div class="title-holder">
						<h5>Three quick steps to begin copying:</h5>
					</div>
					<div class="listing t-center">

						<div class="step-zulu wow fadeIn">
							<img src="assets/images/step1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
							<p>Open and Fund your AAAFx <br>
								ECN zulu Account</p>
						</div>
						<div class="step-zulu wow fadeIn" data-wow-delay="0.3s">
							<img src="assets/images/step2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
							<p>Get Zulu log in credentials in email </p>
						</div>
						<div class="step-zulu wow fadeIn" data-wow-delay="0.6s">
							<img src="assets/images/step3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
							<p>Start copying trades from Zulu Trade</p>
						</div>
					</div>
					<div class="btn-holder t-center">
						<a :href="siteurl2" target="_blank" class="btn btn-yellow">OPEN LIVE ACCOUNT</a>
						<a :href="siteurl2" target="_blank" class="btn btn-blue">OPEN DEMO ACCOUNT</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL
    };
  },
  
};
</script>
